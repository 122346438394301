/* You can add global styles to this file, and also import other style files */
@import "../node_modules/angular-calendar/css/angular-calendar.css";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.header {
  position: fixed;
  width: 100%;
  background-color: white;
  z-index: 50;
  height: 110px;
  border-bottom: 2px solid #e9e9e9;

  .primary {
    height: 60px;
    background-color: var(--baseColor);
    box-shadow: 0px 2px 5px 0px rgba(95, 95, 95, 0.75);
    -webkit-box-shadow: 0px 2px 5px 0px rgb(95 95 95 / 75%);
    -moz-box-shadow: 0px 2px 5px 0px rgba(95, 95, 95, 0.75);

    img {
      display: block;
      margin: auto;
      margin-top: 5px;
      height: 50px;
    }
  }

  .secondary {
    height: 50px;
    background-color: transparent;
    border-bottom: 2px solid #e4e4e6;
  }
}

:root {
  --baseColor: #296BA1;
  --baseColorActive: #215986;
  --baseColor-filter: invert(41%) sepia(33%) saturate(969%) hue-rotate(165deg) brightness(85%) contrast(95%);
  --primary: #367be2;
  --primaryActive: #0a5bd6;
  --lightPrimary: #c6ddf4;
  --secondary: #e7f0ff;
  --silverPrimary: #c7c7c7;
  --silverPrimaryActive: #a3a3a3;
  --silverPrimary-lite: #f5f5f5;
  --silverPrimaryActive-lite: #dadada;
  --silverSecondary: #a0aec0;
  --separatorColor: #1976d2;
  --red: #ff4f5b;
  --redActive: #fd3947;
  --orange: #ffb94f;
  --orangeActive: #ffa000;
  --green: #39ce7f;
  --header-dialog-color: #1970b6;
}

::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: var(--primary) !important;
}

:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: var(--primary) !important;
  opacity: 1;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: var(--primary) !important;
  opacity: 1;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: var(--primary) !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: var(--primary) !important;
}

::placeholder {
  /* Most modern browsers support this now. */
  color: var(--primary);
}

.mat-expansion-indicator::after {
  color: #fff;
  height: 18px;
  width: 18px;
  vertical-align: baseline !important;
}

.form {
  background-color: white !important;
  padding: 15px;
  margin-top: 15px;
  border-bottom-right-radius: 10px !important;
  border-bottom-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
  // box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
}

.form-control {
  margin: 10px 0 10px 0;
}

.title {
  color: var(--primary);
  text-align: left;
}

h1.title {
  font-size: 34px;
}

h2.title {
  color: #a7b2c5;
  font-size: 15px;
}

h2.section-title {
  text-align: left;
  color: #223e6d;
  font-size: 22px;
}

h3.section-title {
  text-align: left;
  color: #223e6d;
  font-size: 20px;
}

.subtitle {
  color: #000b8c;
  text-align: left;
  margin-top: 10px;
}

.subtitle-black {
  text-align: left;
  margin-top: 10px;
  font-weight: bold !important;
}

hr.solid {
  border-top: 3px solid var(--separatorColor);
}

.solidLeft {
  border-left: groove 0.07cm var(--silverPrimary);
}

.custom-button {
  background-color: var(--primary);
  color: white;
}

/*Begin custom-btn-primary*/

.custom-btn-primary {
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  color: white;
}

.custom-btn-primary.active,
.custom-btn-primary:hover {
  background-color: var(--primaryActive) !important;
  border-color: var(--primaryActive) !important;
  color: white;
}

/*End custom-btn-primary*/

.custom-btn-base {
  background-color: var(--baseColor) !important;
  border-color: var(--baseColor) !important;
  color: white;
}

.custom-btn-base.active,
.custom-btn-base:hover {
  background-color: var(--baseColorActive) !important;
  border-color: var(--baseColorActive) !important;
  color: white;
}

/*Begin custom-btn-red*/
.custom-btn-red {
  background-color: var(--red) !important;
  border-color: var(--red) !important;
  color: white;
}

.custom-btn-red.active,
.custom-btn-red:hover {
  background-color: var(--redActive) !important;
  border-color: var(--redActive) !important;
  color: white;
}

/*End custom-btn-red*/

/*Begin custom-btn-orange*/

.custom-btn-orange {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  color: white;
}

.custom-btn-orange.active,
.custom-btn-orange:hover {
  background-color: var(--orangeActive) !important;
  border-color: var(--orangeActive) !important;
  color: white;
}

/*End custom-btn-orange*/

/*Begin custom-btn-silver*/

.custom-btn-silver {
  background-color: var(--silverPrimary-lite) !important;
  border-color: var(--silverPrimary-lite) !important;
  color: #000;
}

.custom-btn-silver.active,
.custom-btn-silver:hover {
  background-color: var(--silverPrimaryActive-lite) !important;
  border-color: var(--silverPrimaryActive-lite) !important;
  color: #000;
}

/*End custom-btn-silver*/

/*Begin disabled btn*/

.custom-btn-primary:disabled,
.custom-btn-red:disabled,
.custom-btn-orange:disabled,
.custom-btn-base:disabled {
  background-color: var(--silverPrimary) !important;
  border-color: var(--silverPrimary) !important;
  color: white;
}

/*End disabled btn*/

.custom-btn-grey {
  background-color: white !important;
  border: solid 1px var(--silverPrimary) !important;
  color: #000;
}

.custom-btn-grey.active {
  background-color: white !important;
  border: solid 1px var(--silverPrimaryActive) !important;
}

.custom-btn-grey:hover {
  background-color: var(--silverPrimaryActive) !important;
}

.bg-green {
  background-color: var(--green);
}

.color-green {
  color: var(--green) !important;
}

.bg-red {
  background-color: var(--red);
}

.bg-baseColor {
  background-color: var(--baseColor) !important;
}

.color-red {
  color: var(--red);
}

.color-primary {
  color: var(--primary);
}

.color-orange {
  color: var(--orange);
}

.color-baseColor {
  color: var(--baseColor);
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

/*Begin mat-calendar*/

mat-calendar {
  .mat-calendar-header {
    background-color: var(--primary) !important;
    border-radius: 12px 12px 0 0;
    padding-top: 0px !important;
    color: white;
  }

  .mat-calendar-controls {
    margin-top: 0px !important;
  }
}

/*End mat-calendar*/

.shadow-box {
  box-shadow: 0px 0px 10px #000;
  border-radius: 10px;
}

/*Begin mat-spinner*/

.spinner-center {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.spinner-overlay {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 10;
  top: 0;
  left: 0;
  position: absolute;
  border-radius: 10px;
}

.spinner circle {
  stroke: var(--primary);
}

/*End mat-spinner*/

/*Begin mat-slide-toggle*/

.mat-slide-toggle.mat-checked {
  .mat-slide-toggle-thumb {
    background-color: var(--primary) !important;
  }

  .mat-slide-toggle-bar {
    background-color: var(--lightPrimary) !important;
  }
}

/*End mat-slide-toggle*/

.dialog-header {
  background-color: var(--header-dialog-color);

  h1 {
    padding-top: 16px;
    font-weight: 500;
    color: white;

    .dialog-header-title {
      margin-left: 15px;
    }

    .dialog-header-close {
      margin-right: 25px;
      float: right !important;
      cursor: pointer;
    }
  }
}

// begin mde-popover

.mde-popover-content {
  overflow-x: hidden;
  margin-top: 8px !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  box-shadow: 0px 0px 16px -7px rgb(0 0 0 / 75%);
  -webkit-box-shadow: 0px 0px 16px -7px rgb(0 0 0 / 75%);
  -moz-box-shadow: 0px 0px 16px -7px rgb(0 0 0 / 75%);
  border-radius: 10px;
}

.mde-popover-content::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.mde-popover-content::-webkit-scrollbar-track {
  background: transparent;
}

.mde-popover-content::-webkit-scrollbar-thumb {
  background-color: var(--silverPrimary);
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,
.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: var(--baseColor);
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: var(--baseColor);
}

.summary-popover {
  width: 350px;
  border-radius: 10px !important;

  .popover-action {
    border-top: solid 1px #f0f0f0;
    padding-right: 25px;
  }

  .mat-card-actions {
    margin-bottom: initial !important;
  }

  .popover-header {
    border-radius: 10px 10px 0px 0px !important;
    height: 40px;
    background-color: white;
    border-bottom: initial;
    padding-top: 15px;
    margin-bottom: 15px;

    .mat-card-header-text {
      margin: initial !important;
    }

    .popover-header-title {
      margin-top: 1px;
    }

    .popover-header-status {
      border-radius: 10px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      height: 25px;
      padding-top: 2px;

      span,
      label,
      b {
        margin-left: initial !important;
        font-size: 0.9em;
      }
    }

    span,
    label,
    b {
      font-weight: 500;
      margin-top: initial !important;
    }
  }

  .popover-content>.row {
    margin-left: 0px !important;
    margin-right: 0px !important;

    span,
    b {
      padding-left: 10px;
    }

    .value {
      color: var(--silverPrimaryActive);
    }
  }
}

// End mde-popover

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-10 {
  width: 10% !important;
}

.w-3 {
  width: 3% !important;
}

.cursor-help {
  cursor: help !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.f-weight-500 {
  font-weight: 500;
}

.text-left {
  text-align: left;
}

.text-end {
  text-align: end;
}

.pl-0 {
  padding-left: 0px !important;
}

.pr-0 {
  padding-right: 0px !important;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.scrollable-content {
  overflow-x: hidden;
}

.scrollable-content::-webkit-scrollbar {
  background: transparent;
  width: 5px;
}

.scrollable-content::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-content::-webkit-scrollbar-thumb {
  background-color: var(--silverPrimary);
}

.border-radius-25 {
  border-radius: 25px !important;
}

::ng-deep .mat-dialog-container {
  border-radius: 25px !important;
  padding: 0px !important;
}

.capitalize {
  text-transform: capitalize;
}

.transfor-rotate-180 {
  transform: rotate(180deg);
}

.bg-transparent {
  background-color: transparent;
}

.center-content {
  margin: 0 auto;
}

.stream {
  height: 100%;
  width: 10px;
  float: right;
  border-left: solid 1px #cccccc66;
}